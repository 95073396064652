@import './vars';
@import './reset';
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
// @import url('https://cdnjs.cloudflare.com/ajax/libs/line-awesome/1.3.0/line-awesome/css/line-awesome.min.css');
// @import url('https://fonts.cdnfonts.com/css/motiva-sans');
// @import './fonts/stylesheet.css';
// @import url('https://fonts.cdnfonts.com/css/arial-2');
// @import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
// @import url('https://fonts.cdnfonts.com/css/verdana');
@import './fonts/style.css';
body {
  background-color: #171d25;

  font-family: 'Arial Regular' !important;
  color: var(--gray-100);
  box-sizing: border-box;
  height: 100%;
}
.main {
  margin-top: 48px;
}
body {
  height: 100%;
}

* {
  box-sizing: border-box;
}
.container {
  padding: 0 10px;
  margin: 0 auto;
  max-width: calc(1280px - 20px);
  width: 100%;
}
.container-game {
  padding: 0;
  width: 100%;
}
.roulette-pro-wrapper {
  height: 105px !important;
}
.roulette-pro-regular-design-top {
  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    // mask-image: url(../public/img/arrow-top.svg);
    background-color: var(--green-500);
    mask-repeat: no-repeat;
    mask-size: contain;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  width: 20px !important;
  background: transparent !important;
  box-shadow: none !important;
  // background: var(--green-500) !important;
}
.loader {
  color: #67c1f5;
  font-size: 10px;

  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  top: 53px;
  left: 1.5px;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0) scale(0.25);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
