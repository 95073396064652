.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #1b2838;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 16px;
  padding: 0 26px 20px 26px;
  // padding: 24px 24px 28px;
}
.title {
  // font-weight: 600;
  font-size: 20px;
  align-self: start;
  margin-bottom: 26px;
}
.inputBox {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.inputEmpty {
}
.info {
  position: absolute;
  bottom: 8px;
  left: 0;
  font-size: 10px;
  color: #8f98a0;
}
.input {
  // padding: 15px 0 14px;
  padding: 24px 0px 24px;
  &::placeholder {
    color: #dadada;
    transition: all 0.3s;
    opacity: 1;
    transition-delay: 0.3s !important;
  }
  color: #fefefe;
  font-size: 15px;
  // font-weight: 600;
  background: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  &:focus::placeholder,
  &:active::placeholder {
    opacity: 0;
    transition-delay: 0s !important;
  }
  &:focus ~ .label,
  &:active ~ .label {
    opacity: 1 !important;
    visibility: visible !important;
    transition-delay: 0.3s !important;
  }
}
.label {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  position: absolute;
  font-size: 10px;
  top: 10px;
  left: 0;
  color: #acb2b8;
}
.headBottom {
  display: flex;
  flex-direction: column;
  align-items: start;
  // margin-top: 15px;
}
.labelShow {
  visibility: visible;
  opacity: 1;
}
.inputError {
  &::placeholder {
    color: #ff0f0f;
  }
  color: #ff0f0f;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.divider {
  height: 1px;
  width: calc(100% + 52px);
  // margin: 22px 0;
  // margin-left: -26px;
  background: #21333a;
  transition: all 0.3s;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to right, #47bfff 5%, #1a44c2 60%);
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }
}
.dividerActive {
  &::after {
    // width: 100%;
    opacity: 1;
    visibility: visible;
  }
}
.whereImage {
  width: 100%;
}
.inputBoxRight {
  user-select: none;
  cursor: pointer;
  color: #67c1f5;
  // font-weight: 500;
  white-space: nowrap;
  font-size: 12px;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 37px;
  font-size: 12px;
  background-color: #5c7e10;
  border-radius: 12px;
  gap: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s;
  &:hover {
    background-color: rgba(92, 126, 16, 0.8);
  }
  // font-weight: 600;
}
.payments {
  margin-top: 21px;
  display: grid;
  grid-template-columns: repeat(3, 90px);
  align-items: start;
  justify-content: start;
  margin-bottom: 26px;
  column-gap: 10px;
  margin-right: auto;
}
.payment {
  border: 3px solid transparent;
  cursor: pointer;
  & img {
    padding: 10px;
    width: 100%;
    display: block;
  }
  &Active {
    border-radius: 14px;
    border: 3px solid #01b81a;
  }
}
.total {
  &:first-child {
    margin-top: 20px !important;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #8f98a0;
  // margin-top: 8px;
  margin-bottom: 14px;
  font-size: 14px;
  // font-weight: 600;
  align-self: start;
}
.headWrap {
  max-width: 600px;
  margin: 0 auto;
}
.head {
  margin-top: 20px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: start;
}
.robotWrap {
  margin-right: 15px;
  width: 60px;
  height: 60px;
  background-color: #1b2838;
  border-radius: 12px;
}
.robot {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  padding: 10px;
}
.headTitle {
  // font-weight: 500;
  font-size: 19px;
  // margin-top: 20px;
}
.headDesc {
  margin-top: 8px;
  line-height: 18px;
  font-size: 14px;
  color: #496887;
  text-align: start;
}
.checkIcon {
  transform: translateY(1px);
}
.checkText {
  margin-left: 5px;
}
.checkSuccess {
  color: #01b81a;
}
.checkError {
  color: #ff0f0f;
}
.checkStatus {
  position: absolute;
  top: 50px;
  left: 0;
  display: flex;
  font-size: 11px;
  align-items: center;
  justify-content: center;
}
.checkSuccess {
}
.checkError {
}
.loader {
  position: fixed;

  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
.support {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: auto;
}
.supportTitle {
  font-size: 14px;
  // margin-top: 10px;
  margin-bottom: 5px;
}
.supportIcon {
  user-select: none;
  color: #67c1f5;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 5px;
  // margin-bottom: 8px;
  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(../../../../public/img/telegram.svg);
  }
}
.supportEmail {
  border-radius: 50%;
  // padding: 2px;
  margin-bottom: -2px;
  width: 18px;
  height: 18px;
  &::before {
    background-image: url(../../../../public/img/email.svg);
    width: 100%;
    height: 100%;
    background-size: contain;
  }
}
.supportLinks {
  display: flex;
  align-items: center;
}
.supportLink {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  color: #67c1f5;
  white-space: nowrap;
  font-size: 12px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

@media (max-width: 440px) {
  .robotWrap {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    margin-right: 12px;
  }
  .info {
    font-size: 9px;
    bottom: 9px;
  }
  .headTitle {
    // margin-top: 4px;
    font-size: 15px;
  }
  .headDesc {
    margin-top: 2px;
    font-size: 11px;
    line-height: 12px;
  }
}
.partner {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: auto;
}
.partnerTitle {
  font-size: 14px;
  // margin-top: 10px;
  margin-bottom: 5px;
}

.partnerText {
  font-size: 13px;
  color: #8f98a0;
  line-height: 18px;
}
.partnerInputWrap {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 10px;
  position: relative;
  font-size: 14px;
  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    left: 0;
    bottom: 0;
    width: calc(100% - 139px - 10px);
    background: linear-gradient(to right, #47bfff 5%, #1a44c2 60%);
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }
}
.partnerInputWrapActive {
  &::after {
    opacity: 1;
    visibility: visible;
  }
}
.partnerBtn {
  padding: 5px 50px;
  background: linear-gradient(to right, #47bfff 5%, #1a44c2 60%);
  transform: all 0.3s;

  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  background-position: 25%;
  background-size: 330% 100%;
  border-radius: 2px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-position: 40%;
  }
}

.partnerInput {
  // background-color: #21333a;
  border-bottom: 2px solid #21333a;
  padding: 10px 10px;
  padding-left: 0;
  position: relative;
}
.partnerLabel {
  top: -5px;
  left: 0px;
}
@media (max-width: 440px) {
  .partnerInputWrap {
    width: 100%;
    max-width: none;
    row-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 40px;
    &::after {
      bottom: 50px;
      width: 100%;
    }
  }
}
.partnerLoader {
  left: 7px !important;
  top: 50px !important;
}
.partnerStatus {
  position: absolute;
  bottom: 0;
  left: 0;
}
.exitBtn {
  cursor: pointer;
  margin-top: 10px;
  border-radius: 2px;
  padding: 10px 25px;
  font-size: 14px;
  background-color: #171d25;
}
.promoInput {
  &::placeholder {
    text-transform: none;
  }
}
