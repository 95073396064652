.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e6e9ea;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 13px;
  padding: 30px 0 22px;
  color: #000;
  // padding: 24px 24px 28px;
}
.icon {
  max-width: 60px;
  min-height: 60px;
  //   background-color: #000;
}
.title {
  margin-top: 28px;
  font-size: 19px;
}
.sum {
  margin-top: 28px;
  font-size: 26px;
}
.steam {
  margin-top: 10px;
  font-size: 18px;
  text-shadow: 1px 0 0 currentColor;
}
.text {
  font-size: 12px;
  max-width: 220px;
  text-align: center;
  color: #8f98a0;
  margin-top: 22px;
}
.button {
  cursor: pointer;
  margin-top: 28px;
  max-width: 215px;
  height: 36px;
  border-radius: 13px;
  width: 100%;
  color: #e3ecd7;
  font-size: 12px;
  background-color: #b1b1b1;
}
.divider {
  background-color: #c2c2c2;
  margin-top: 24px;
  margin-bottom: 18px;
  height: 1px;
  width: 100%;
}
.supportTitle {
  margin-bottom: 15px;
}
.supportTelegram {
  user-select: none;
  color: #67c1f5;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  &::before {
    content: '';
    display: block;
    width: 19px;
    height: 19px;
    background-image: url(../../../../public/img/telegram.svg);
    margin-right: 5px;
  }
}
.supportEmail {
  font-size: 16px;
  text-decoration: underline;
  color: #000000;
  text-align: center;
  text-underline-offset: 4px;
}
