.wrap {
  display: flex;
  align-items: start;
  flex-direction: column;
  background-color: #1b2838;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 16px;
  padding: 26px 26px 28px;
  margin-bottom: 50px;
}
.title {
  // font-weight: 600;
  font-size: 20px;
  align-self: start;
  margin-bottom: 8px;
}
.list {
  width: 100%;
}
