.wrap {
  width: 100%;
  margin: 40px 0;
  &:last-child {
    margin-bottom: 0;
    .content {
      padding-bottom: 0;
    }
  }
}
.head {
  user-select: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   color: #748b8d;
  font-size: 16px;
  // font-weight: 500;
}
.title {
}
.iconActive {
  transform: scaleY(-1) !important;
}
.icon {
  width: 18px;
  height: 18px;
}
.content {
  line-height: 24px;
  padding-top: 24px;
  color: #8f98a0;
  font-size: 14px;
  & a {
    cursor: pointer;
    color: #67c1f5;
    white-space: nowrap;

    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  }
}
