.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
  margin-top: 50px;
}
.copy {
  color: #8f98a0;
  font-size: 12px;
}
.link {
  font-size: 12px;
  color: #67c1f5;
  text-underline-offset: 3px;
}
.text {
  color: #8f98a0;
  margin-top: 10px;
  font-size: 12px;
  margin-bottom: 10px;
}
