:root {
  --white: #ffffff;
  --black: #000000;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;

  --gray-100-rgb: 243, 244, 246;
  --gray-200-rgb: 229, 231, 235;
  --gray-300-rgb: 209, 213, 219;
  --gray-400-rgb: 156, 163, 175;
  --gray-500-rgb: 107, 114, 128;
  --gray-600-rgb: 75, 85, 99;
  --gray-700-rgb: 55, 65, 81;
  --gray-800-rgb: 31, 41, 55;
  --gray-900-rgb: 17, 24, 39;

  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;
  --orange-50: #fff7ed;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;
  --yellow-50: #fffbeb;
  --yellow-100: #fef3c7;
  --yellow-200: #fde68a;
  --yellow-300: #fcd34d;
  --yellow-400: #fbbf24;
  --yellow-500: #f59e0b;
  --yellow-600: #d97706;
  --yellow-700: #b45309;
  --yellow-800: #92400e;
  --yellow-900: #78350f;
  --green-50: #ecfdf5;
  --green-100: #d1fae5;
  --green-200: #a7f3d0;
  --green-300: #6ee7b7;
  --green-400: #34d399;
  --green-500: #10b981;
  --green-600: #059669;
  --green-700: #047857;
  --green-800: #065f46;
  --green-900: #064e3b;
  --teal-50: #f0fdfa;
  --teal-100: #ccfbf1;
  --teal-200: #99f6e4;
  --teal-300: #5eead4;
  --teal-400: #2dd4bf;
  --teal-500: #14b8a6;
  --teal-600: #0d9488;
  --teal-700: #0f766e;
  --teal-800: #115e59;
  --teal-900: #134e4a;
  --cyan-50: #ecfeff;
  --cyan-100: #cffafe;
  --cyan-200: #a5f3fc;
  --cyan-300: #67e8f9;
  --cyan-400: #22d3ee;
  --cyan-500: #06b6d4;
  --cyan-600: #0891b2;
  --cyan-700: #0e7490;
  --cyan-800: #155e75;
  --cyan-900: #164e63;
  --light-blue-50: #f0f9ff;
  --light-blue-100: #e0f2fe;
  --light-blue-200: #bae6fd;
  --light-blue-300: #7dd3fc;
  --light-blue-400: #38bdf8;
  --light-blue-500: #0ea5e9;
  --light-blue-600: #0284c7;
  --light-blue-700: #0369a1;
  --light-blue-800: #075985;
  --light-blue-900: #0c4a6e;
  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;
  --indigo-50: #eef2ff;
  --indigo-100: #e0e7ff;
  --indigo-200: #c7d2fe;
  --indigo-300: #a5b4fc;
  --indigo-400: #818cf8;
  --indigo-500: #6366f1;
  --indigo-600: #4f46e5;
  --indigo-700: #4338ca;
  --indigo-800: #3730a3;
  --indigo-900: #312e81;
  --purple-50: #f5f3ff;
  --purple-100: #ede9fe;
  --purple-200: #ddd6fe;
  --purple-300: #c4b5fd;
  --purple-400: #a78bfa;
  --purple-500: #8b5cf6;
  --purple-600: #7c3aed;
  --purple-700: #6d28d9;
  --purple-800: #5b21b6;
  --purple-900: #4c1d95;
  --pink-50: #fdf2f8;
  --pink-100: #fce7f3;
  --pink-200: #fbcfe8;
  --pink-300: #f9a8d4;
  --pink-400: #f472b6;
  --pink-500: #ec4899;
  --pink-600: #db2777;
  --pink-700: #be185d;
  --pink-800: #9d174d;
  --pink-900: #831843;
  --rose-50: #fff1f2;
  --rose-100: #ffe4e6;
  --rose-200: #fecdd3;
  --rose-300: #fda4af;
  --rose-400: #fb7185;
  --rose-500: #f43f5e;
  --rose-600: #e11d48;
  --rose-700: #be123c;
  --rose-800: #9f1239;
  --rose-900: #881337;

  --text-xs: 12px;
  --text-sm: 14px;
  --text-md: 16px;
  --text-lg: 18px;
  --text-xl: 20px;
  --text-2xl: 24px;
  --text-3xl: 30px;
  --text-4xl: 36px;
  --text-5xl: 48px;
  --text-6xl: 60px;
  --text-7xl: 72px;
  --text-8xl: 96px;
  --text-9xl: 128px;

  --primary-50: #ecfdf5;
  --primary-100: #d1fae5;
  --primary-200: #a7f3d0;
  --primary-300: #6ee7b7;
  --primary-400: #34d399;
  --primary-500: #10b981;
  --primary-600: #059669;
  --primary-700: #047857;
  --primary-800: #065f46;
  --primary-900: #064e3b;

  /* --gray-50-rgb: 249, 250, 251;
  --gray-100-rgb: 243, 244, 246;
  --gray-200-rgb: 229, 231, 235;
  --gray-300-rgb: 209, 213, 219;
  --gray-400-rgb: 156, 163, 175;
  --gray-500-rgb: 107, 114, 128;
  --gray-600-rgb: 75, 85, 99;
  --gray-700-rgb: 55, 65, 81;
  --gray-800-rgb: 31, 41, 55;
  --gray-900-rgb: 17, 24, 39; */
  --red-50-rgb: 254, 242, 242;
  --red-100-rgb: 254, 226, 226;
  --red-200-rgb: 254, 202, 202;
  --red-300-rgb: 252, 165, 165;
  --red-400-rgb: 248, 113, 113;
  --red-500-rgb: 239, 68, 68;
  --red-600-rgb: 220, 38, 38;
  --red-700-rgb: 185, 28, 28;
  --red-800-rgb: 153, 27, 27;
  --red-900-rgb: 127, 29, 29;
  --orange-50-rgb: 255, 247, 237;
  --orange-100-rgb: 255, 237, 213;
  --orange-200-rgb: 254, 215, 170;
  --orange-300-rgb: 253, 186, 116;
  --orange-400-rgb: 251, 146, 60;
  --orange-500-rgb: 249, 115, 22;
  --orange-600-rgb: 234, 88, 12;
  --orange-700-rgb: 194, 65, 12;
  --orange-800-rgb: 154, 52, 18;
  --orange-900-rgb: 124, 45, 18;
  --yellow-50-rgb: 255, 251, 235;
  --yellow-100-rgb: 254, 243, 199;
  --yellow-200-rgb: 253, 230, 138;
  --yellow-300-rgb: 252, 211, 77;
  --yellow-400-rgb: 251, 191, 36;
  --yellow-500-rgb: 245, 158, 11;
  --yellow-600-rgb: 217, 119, 6;
  --yellow-700-rgb: 180, 83, 9;
  --yellow-800-rgb: 146, 64, 14;
  --yellow-900-rgb: 120, 53, 15;
  --green-50-rgb: 236, 253, 245;
  --green-100-rgb: 209, 250, 229;
  --green-200-rgb: 167, 243, 208;
  --green-300-rgb: 110, 231, 183;
  --green-400-rgb: 52, 211, 153;
  --green-500-rgb: 16, 185, 129;
  --green-600-rgb: 5, 150, 105;
  --green-700-rgb: 4, 120, 87;
  --green-800-rgb: 6, 95, 70;
  --green-900-rgb: 6, 78, 59;
  --teal-50-rgb: 240, 253, 250;
  --teal-100-rgb: 204, 251, 241;
  --teal-200-rgb: 153, 246, 228;
  --teal-300-rgb: 94, 234, 212;
  --teal-400-rgb: 45, 212, 191;
  --teal-500-rgb: 20, 184, 166;
  --teal-600-rgb: 13, 148, 136;
  --teal-700-rgb: 15, 118, 110;
  --teal-800-rgb: 17, 94, 89;
  --teal-900-rgb: 19, 78, 74;
  --cyan-50-rgb: 236, 254, 255;
  --cyan-100-rgb: 207, 250, 254;
  --cyan-200-rgb: 165, 243, 252;
  --cyan-300-rgb: 103, 232, 249;
  --cyan-400-rgb: 34, 211, 238;
  --cyan-500-rgb: 6, 182, 212;
  --cyan-600-rgb: 8, 145, 178;
  --cyan-700-rgb: 14, 116, 144;
  --cyan-800-rgb: 21, 94, 117;
  --cyan-900-rgb: 22, 78, 99;
  --light-blue-50-rgb: 240, 249, 255;
  --light-blue-100-rgb: 224, 242, 254;
  --light-blue-200-rgb: 186, 230, 253;
  --light-blue-300-rgb: 125, 211, 252;
  --light-blue-400-rgb: 56, 189, 248;
  --light-blue-500-rgb: 14, 165, 233;
  --light-blue-600-rgb: 2, 132, 199;
  --light-blue-700-rgb: 3, 105, 161;
  --light-blue-800-rgb: 7, 89, 133;
  --light-blue-900-rgb: 12, 74, 110;
  --blue-50-rgb: 239, 246, 255;
  --blue-100-rgb: 219, 234, 254;
  --blue-200-rgb: 191, 219, 254;
  --blue-300-rgb: 147, 197, 253;
  --blue-400-rgb: 96, 165, 250;
  --blue-500-rgb: 59, 130, 246;
  --blue-600-rgb: 37, 99, 235;
  --blue-700-rgb: 29, 78, 216;
  --blue-800-rgb: 30, 64, 175;
  --blue-900-rgb: 30, 58, 138;
  --indigo-50-rgb: 238, 242, 255;
  --indigo-100-rgb: 224, 231, 255;
  --indigo-200-rgb: 199, 210, 254;
  --indigo-300-rgb: 165, 180, 252;
  --indigo-400-rgb: 129, 140, 248;
  --indigo-500-rgb: 99, 102, 241;
  --indigo-600-rgb: 79, 70, 229;
  --indigo-700-rgb: 67, 56, 202;
  --indigo-800-rgb: 55, 48, 163;
  --indigo-900-rgb: 49, 46, 129;
  --purple-50-rgb: 245, 243, 255;
  --purple-100-rgb: 237, 233, 254;
  --purple-200-rgb: 221, 214, 254;
  --purple-300-rgb: 196, 181, 253;
  --purple-400-rgb: 167, 139, 250;
  --purple-500-rgb: 139, 92, 246;
  --purple-600-rgb: 124, 58, 237;
  --purple-700-rgb: 109, 40, 217;
  --purple-800-rgb: 91, 33, 182;
  --purple-900-rgb: 76, 29, 149;
  --pink-50-rgb: 253, 242, 248;
  --pink-100-rgb: 252, 231, 243;
  --pink-200-rgb: 251, 207, 232;
  --pink-300-rgb: 249, 168, 212;
  --pink-400-rgb: 244, 114, 182;
  --pink-500-rgb: 236, 72, 153;
  --pink-600-rgb: 219, 39, 119;
  --pink-700-rgb: 190, 24, 93;
  --pink-800-rgb: 157, 23, 77;
  --pink-900-rgb: 131, 24, 67;
  --rose-50-rgb: 255, 241, 242;
  --rose-100-rgb: 255, 228, 230;
  --rose-200-rgb: 254, 205, 211;
  --rose-300-rgb: 253, 164, 175;
  --rose-400-rgb: 251, 113, 133;
  --rose-500-rgb: 244, 63, 94;
  --rose-600-rgb: 225, 29, 72;
  --rose-700-rgb: 190, 18, 60;
  --rose-800-rgb: 159, 18, 57;
  --rose-900-rgb: 136, 19, 55;
}
